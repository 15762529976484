import { lighten, darken } from 'polished';
import { Theme } from './styled';
import logo from '../images/normec.svg';

const brand = {
    primary: '#DE782D',
    secondary: '#000000',
    brandIcon: 'normec'
};

const colors = {
    primary: brand.primary,
    primary_hover: darken(0.075, brand.primary),
    primary2: '#ad622c',
    primary3: '#985d32',
    primary4: '#764c2d',
    primary5: '#764c2d',
    primaryLight: lighten(.05, brand.primary),
    secondary: brand.secondary,
    secondary_hover: darken(0.1, brand.secondary),
    purple: '#9333EA',
    red: '#E02A2A',
    orange: '#EA960F',
    lightOrange: lighten(0.35, '#EA960F'),
    medium: '#A2A2A2',
    black: '#000000',
    white: '#FFFFFF',
    light: '#F0F3F3',
    border: '#E3E3E3',
    placeholder: lighten(0.075, brand.secondary),
    dark: '#002F3E',
    yellow: '#A7C532',
    gray: {
        10: '#FAFAFA',
        25: '#F5F7F8',
        50: '#ECEFF1',
        100: '#CFD8DC',
        300: '#90A4AE',
        500: '#607D8B',
        700: '#455A64',
        900: '#263238'
    },
    blue: '#2790E9',
    magenta: '#CC00CC',
    headerBackground: '#FFFFFF',
    headerText: '#1C1B1F',
    statusSuccess: '#81CA58',
    statusWarning: '#EA9E46',
    statusError: '#E04C44',
};

const borderRadius = {
    small: '4px',
    normal: '8px'
};

const fontSizes = {
    normal: 14,
    h1: 40,
    h2: 32,
    h3: 24,
    h4: 20,
    bigger: 18,
    smaller: 12
};

const theme: Theme = {
    colors,
    fontSizes,
    borderRadius,
    constants: {
        borderRadius: 8, // old
        spacer: 30,
        panelPadding: 12,
        menuWidth: 300,
        topBarHeight: 75,
        footerHeight: 24,
        breadCrumbHeight: 40,
        header: {
            height: '5rem'
        }
    },
    breakpoints: [400, 600, 900, 1200, 1550],
    brand,
    logo
};

export default theme;
